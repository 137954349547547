import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LandingState } from '@app/shared/state/landing.state';

const getLandingFeatureState = createFeatureSelector<LandingState>('landing');

export const isParentSelected = createSelector(
  getLandingFeatureState,
  (state) => state.parentSelected,
);

export const getClaims = createSelector(getLandingFeatureState, (state) => state.claims);
export const getProfilePictures = createSelector(
  getLandingFeatureState,
  (state) => state.profilePictures,
);

export const getSelectedClaim = createSelector(
  getLandingFeatureState,
  (state) => state.selectedClaim,
);
